import React from "react"
import { Typography, Card } from "antd"
import { Animated } from "react-animated-css"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import TrustedBy from "../../components/TrustedBy"
import DevelopmentProcess from "../../components/WEB_DevelopmentProcess"
import SideForm from "./../../components/SideForm"
import behindImageSmall from "../../images/Group 495@2x.png"
import manual_testing from '../../images/manual_testing-01.png'
import mobile_testing from '../../images/mobile_testing-01.png'
import saas_testing from '../../images/saas_testing-01.png'
import usability_testing from '../../images/usability_testing-01.png'
import web_testing from '../../images/web_testing-01.png'
import ServiceCard from "../../components/ServiceCard"
import ServiceStepsProcess from "../../components/ServiceStepsProcess"

import "../../styles/services.scss"
import "../../styles/animate.css"
import FeaturedWork from "../../components/MOB_FeaturedWork"

const { Title } = Typography

const Index = ({ data }) => {
  const backgroundImage = data.bgImage.childImageSharp.fluid.src
  return (
    <Layout className="services-page-container" data={data} 
    pageTitle={"Visiomate - Quality Assurance Services"}
    descrip = {"Ensure flawless software with our comprehensive quality assurance services. Our expert QA team delivers reliable testing and bug-free solutions. Learn more!"}>
      <div className="top-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="logo">
          <Link to="/" >
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>

        <div className="box-wrapper text-and-form-container">
          <div className="information">
            <Animated
              animationIn="fadeInUp"
              animationInDuration={500}
              animationInDelay={300}
              isVisible={true}
            >
              <Title>We Offer Innovative AI Development Solutions to Transform Your Business</Title>
              <br />
              <p>
              Leverage the power of artificial intelligence with our cutting-edge AI development services.
              We specialize in creating custom AI solutions that enhance operational efficiency, improve
              decision-making, and drive business growth. <br />
                <br />
                From machine learning to natural language processing, our expert team will help you harness
                AI technology tailored to your specific needs. Start your journey towards innovation today!
              </p>
              <TrustedBy showHeading={false} />
            </Animated>
          </div>
          <Animated
            animationIn="fadeInDown"
            animationInDuration={800}
            animationInDelay={500}
            isVisible={true}
            className="form"
          >
            <SideForm />
          </Animated>
        </div>
      </div>
      <div className="services-description-section">
      <div className="service-heading-and-detail">
        <Title>AI Development</Title>
      </div>
      <div className="service-cards-wrapper">
        <ServiceCard img_src={manual_testing} img_alt={'manual_testing'} card_heading={'Custom AI software development'} card_para={'Unlike off-the-shelf AI applications, which are generic and aimed at a broad audience, custom AI software is built from the ground up to address specific operational requirements and business objectives.'}/>
        <ServiceCard img_src={usability_testing} img_alt={'usability_testing'} card_heading={'Chatbot development'} card_para={'These applications, known as chatbots, utilize artificial intelligence (AI) technologies, particularly natural language processing (NLP) and machine learning (ML), to understand user inputs and provide relevant responses.'}/>
        <ServiceCard img_src={web_testing} img_alt={'web_testing'} card_heading={'Machine Learning Integration'} card_para={'The process of incorporating ML models and algorithms into existing software applications or systems to enhance their functionality and performance. Leverage data-driven insights, automate processes, and improve decision-making.'}/>
        <ServiceCard img_src={mobile_testing} img_alt={'mobile_testing'} card_heading={'Enterprise AI Integration'} card_para={'This integration enables businesses to leverage AI capabilities to enhance operational efficiency, improve decision-making, and drive innovation across various departments.'}/>
        <ServiceCard img_src={saas_testing} img_alt={'saas_testing'} card_heading={'Process Automation'} card_para={'The primary goal of process automation is to enhance efficiency, reduce human intervention, and improve the overall effectiveness of workflows.'}/>
        <ServiceCard card_heading={'Let connect, book a free consultancy session with our experts'} card_button={"Free Consultancy"}/>
      </div>
        <div className="behindimagediv">
            <img
              className="behindImageSmall"
              src={behindImageSmall}
              alt="yellow-triangle"
            />
            <img className="behindImageLarg" src={behindImageSmall} alt="yellow-triangle" />
        </div>
      </div>
      {/* <div className="featured-projects">
        <FeaturedWork data={data} />
      </div> */}
      <DevelopmentProcess title='AI Development Services Process' >
        <ServiceStepsProcess process_heading='Inception & Elicitation' process_para="Talk to the customer Listen to what the customer has to say Brainstorm and prepare project backlog " process_step_number="1" process_indentify="odd" />
        <ServiceStepsProcess process_heading='Plan sprint' process_para="The team reviews the product backlog and selects items they believe can be completed within the sprint timeframe." process_step_number="2" />
        <ServiceStepsProcess process_heading='Execute Sprint' process_para="The team focuses on completing the items in the sprint backlog, which consists of tasks selected from the product backlog based on priority and team capacity." process_step_number="3" process_indentify="odd" iconShow="dark"/>
        <ServiceStepsProcess process_heading='Review and Deliver' process_para="During this step, the development team showcases the work completed, demonstrating new features and functionalities to the customer." process_step_number="4" iconShow="dark" />
        <ServiceStepsProcess process_heading='Support & maintenance' process_para="A critical phases in the software development lifecycle, ensuring that applications are successfully launched and maintained for optimal performance." process_step_number="5" process_indentify="odd" />
      </DevelopmentProcess>
    </Layout>
  )
}

export default Index

export const  aiSolution= graphql`
  query aiSolution {
    FarmManagementSystem: file(
      relativePath: { eq: "FarmManagementSystem/pf.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISOComplianceManagement: file(
      relativePath: { eq: "ISOComplianceManagement/iso.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TenderAssetRecordManagement: file(
      relativePath: { eq: "TenderAssetRecordManagement/tender.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BigJerry: file(relativePath: { eq: "mobile/BigJerryApp.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ViztosPos: file(relativePath: { eq: "ViztosPos/vistos_image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo1: file(relativePath: { eq: "Odoo/odoo1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo2: file(relativePath: { eq: "Odoo/odoo2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo3: file(relativePath: { eq: "Odoo/odoo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Custom: file(relativePath: { eq: "Custom/CustDevelopment.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365: file(relativePath: { eq: "D365/D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365_2: file(relativePath: { eq: "D365/D365_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
